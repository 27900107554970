
import Item from './Item.vue';
export default {
  components:{
    Item
  },
  props:{
    title:{
      type:String,
      default:''
    },
    list:{
      type:Array,
      default:()=>[]
    }
  }
}
