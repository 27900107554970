<template>
  <div class="cloud-item">
    <div class="cloud-item-head"><span>{{title}}</span><span @click="$emit('detail')">查看详情</span></div>
    <div class="cloud-item-body clearfix">
      <Item v-for="(item,index) in list" :key="index" :item="item"/>
    </div>
  </div>
</template>
<script lang="ts">
import Item from './Item.vue';
export default {
  components:{
    Item
  },
  props:{
    title:{
      type:String,
      default:''
    },
    list:{
      type:Array,
      default:()=>[]
    }
  }
}
</script>
<style lang="less" scoped>
.cloud-item{
  &:not(:last-child){
    margin-bottom: 30px;
  }
  .cloud-item-head{
    margin: auto;
    width: 94vw;
    height: 28.4vw;
    background: url(../../assets/index/back_6.png) no-repeat center;
    background-size: cover;
    padding: 5.33vw 7.5vw 10.66vw 6.5vw;
    font-size: 22px;
    color: #fff;
    font-weight: bold;
    display: flex;
    justify-content: space-between;
    align-items: center;
    overflow: hidden;
    >span:first-child{
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      flex: 1;
    }
    >span:last-child{
      position: relative;
      font-size: 16px;
      padding-right: 15px;
      font-weight: 400;
      margin-left: 20px;
      &::after,&::before{
        content: '';
        position: absolute;
        right: 0;
        top: 50%;
        width: 8px;
        height: 1px;
        background-color: #fff;
      }
      &::after{
        transform: rotate(45deg) translateY(-4px);
      }
      &::before{
        transform: rotate(-45deg) translateY(4px);
      }
    }
  }
  .cloud-item-body{
    margin: auto;
    width: 94vw;
    box-sizing: border-box;
    border: 5px solid #FF3044;
    border-radius: 20px;
    background-color: #FFE7C5;
    padding: 10px;
    display: flex;
    flex-wrap: wrap;
  }
}
</style>
