<template>
  <div class="container footer-placeholder">
    <img @click="openService" src="/static/img/cloud-ali-banner.jpg" />
    <!-- 产品服务 -->
    <Cloud />
    <div class="cloud-container">
      <!-- <Coupons /> -->
      <CloudItemBox
        v-for="(item, index) in projects"
        :key="index"
        :title="item.name"
        :list="item.projects"
        @detail="goDetail(item.id)"
      />
    </div>
  </div>
  <Footer />
</template>
<script>
import Footer from "@/components/Footer.vue";
import Cloud from "@/components/home/Cloud.vue";
import CloudItemBox from "@/components/cloud/CloudItemBox.vue";
import Coupons from "@/components/home/Coupons.vue";
export default {
  components: {
    Footer,
    Cloud,
    CloudItemBox,
    Coupons,
  },
  data() {
    return {
      projects: [],
    };
  },
  mounted() {
    fetch("/static/data.json")
      .then((response) => response.json())
      .then((data) => {
        this.projects = data;
      });
  },
  methods: {
    goDetail(id) {
      this.$router.push(`/cloud/alicloud/detail/${id}`);
    },
  },
};
</script>
<style lang="less" scoped>
.container {
  > img {
    width: 100%;
  }
  .cloud-container {
    padding-bottom: 40px;
    position: relative;
    &::after {
      content: "";
      position: absolute;
      left: 0;
      right: 0;
      top: 25px;
      bottom: 0;
      background: linear-gradient(0deg, #284cb9, #9a0000);
      z-index: -1;
    }
  }
}
</style>